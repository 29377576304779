export const AuthReducer = (initialState, action) => {
  switch (action.type) {
  case "REQUEST_START":
    return {
      ...initialState,
      loading: true,
      successMessage: null,
      errorMessage: null
    }
  case "USER_INFO":
    return {
      ...initialState,
      user: action.payload.user
    }
  case "NOTIFICATIONS":
    return {
      ...initialState,
      notifications: action.payload.notifications
    }
  case "LOGIN":
    return {
      ...initialState,
      user: action.payload.user,
      loading: false
    }
  case "LOGOUT":
    return {
      ...initialState,
      user: null,
      successMessage: null,
      errorMessage: null
    }
  case "ERROR":
    return {
      ...initialState,
      loading: false,
      successMessage: null,
      errorMessage: action.error
    }
  case "SUCCESS":
    return {
      ...initialState,
      loading: false,
      errorMessage: null,
      successMessage: action.success
    }

  default:
    throw new Error(`Unhandled action type: ${action.type}`)
  }
}
