//export const API_URL = 'https://api.orbyus.co/'
export const API_URL = 'https://developorbyus.crizz.com.co/'
export const API_URL_SOKET = 'ws://158.69.209.248:1336/'

export const ENDPOINTS = {
  LOGIN: '/user/admin/login',
  ADMINS: '/user/admin',
  USER_INFO: 'user/info',
  USER: '/user',
  LANGUAGES: '/language',
  CATEGORIES: '/category',
  SUBCATEGORIES: '/subCategory',
  SUBCATEGORIES_ADMIN: '/admin/subCategory',
  TAGS: '/tags',
  TRACKS: '/track',
  TRACKS_ADMIN: '/track/admin',
  PLAYLIST: 'playList',
  PLAYLIST_ADMIN: 'playList/admin',
  PLANS: 'planes',
  PLANS_ADMIN: 'planes/admin'
}
