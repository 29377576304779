import React, { useEffect, useReducer } from "react"
import io from 'socket.io-client'
import { API_URL_SOKET } from "@src/constants"
// Reducers
import { AuthReducer } from './reducer'
import { toast } from 'react-toastify';

// Context
export const AuthStateContext = React.createContext()

const GetNotifications = (dispatch, u) => {
  console.log("init socket.oi: ")
  const options = {
      transports: ["websocket"]
  }

  const socketIo = io.connect( API_URL_SOKET, options)
  console.log(socketIo)
  socketIo.on("success", async (data) => {
      console.log("success Conneted to socket.oi: ", data)
  })
  socketIo.on("connect", function (data) {
      console.log("socket ON connect")
      console.log("Conneted to socket.oi: ", socketIo.id)
      console.log("Data: ", data )
      //al conectar envias a unirte al room del server, eso lo cuandras con victor
      socketIo.emit("join", {})
  })
  //asi escuchas "message" seria el evento
  socketIo.on("sendNoty",function (notification) {
      console.log("Data: ", notification)
      if(notification.type === "CHAT"){
        if(notification.data.idExpert === u._id){
          toast.success(notification.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
          })
          dispatch({ type:'NOTIFICATIONS', payload: { notifications: notification } })
        }
      }
  })
}

export const AuthProvider = ({ children }) => {
  const initialState = {
    user: null,
    loading: false,
    errorMessage: null,
    successMessage: null,
    notifications:[]
  }

  const [authState, dispatch] = useReducer(AuthReducer, initialState)

  useEffect(() => {
    (async function() {
      const token = window.localStorage.getItem('@TOKEN')
      if (token !== null) {
        const user = JSON.parse( window.localStorage.getItem('@USER') )
        dispatch({ type:'USER_INFO', payload: { user } })
        GetNotifications( dispatch, user )
      }
    })()
  }, [])

  return (
    <AuthStateContext.Provider value={{ ...authState, dispatch }}>
      {children}
    </AuthStateContext.Provider>
  )
}
